<template>
  <tr class="tableRow" @click="openGroup(group.id)">
    <td>{{ group.group_name }}</td>
    <td>{{ group.group_slug }}</td>
    <td>{{ group.amountProducts }}</td>
  </tr>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    group: Object,
  },
  methods: {
    openGroup(groupId) {
      this.$router.push({
        name: "OpenGroup",
        params: { groupId },
      });
    },
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "isLoggedIn",
      isMobile: "isMobile",
    }),
  },
};
</script>

<style scoped lang="scss">
.tableRow {
  cursor: pointer;
  border: 3px solid red;
}

.tableRow:hover {
  background: #fafafa;
}
</style>