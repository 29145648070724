<template>
  <div class="pageWrapper">
    <div class="topbar">
      <div style="display: flex">
        <H2>Groups</H2>
      </div>
      <MainButton
        :clickFunction="openAddGroupPopup"
        title="Add Group"
        styleClass="primary"
      />
    </div>
    <SearchInput placeholder="Search" v-model="searchString" />
    <table class="standardTable">
      <br />
      <tr class="standardTableHeader">
        <th>Group Name</th>
        <th>Group slug</th>
        <th>Amount products</th>
      </tr>
      <LoadingRowTable :dataList="groups" :columnsAmount="3">
        <fragment>
          <GroupItem :key="group.id" :group="group" v-for="group in filtered" />
        </fragment>
      </LoadingRowTable>
    </table>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

import { filteredArrayWithString } from '@/globalFunctions/filterArrayWithString'
import AddGroupPopupBox from '@/components/products/groups/AddGroupPopupBox'
import GroupItem from '@/components/groups/GroupItem'
import LoadingRowTable from '@/components/LoadingRowTable'
import MainButton from '@/layout/MainButton'
import SearchInput from '@/layout/SearchInput'
import H2 from '@/layout/typography/H2'

export default {
  data() {
    return {
      groups: undefined,
      searchString: '',
    }
  },
  components: {
    LoadingRowTable,
    GroupItem,
    MainButton,
    SearchInput,
    H2,
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      isMobile: 'isMobile',
    }),
    filtered() {
      return filteredArrayWithString(this.groups, this.searchString)
    },
  },
  methods: {
    openAddGroupPopup() {
      this.$store.commit('SET_POPUP', { component: AddGroupPopupBox })
    },
  },
  created() {
    axios.post('groups/getGroups').then((res) => {
      this.groups = res.data
    })
  },
}
</script>

<style scoped lang="scss">
.divider {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 70% minmax(30%, 200px);
  grid-gap: 20px;
}

@media screen and (max-width: 1000px) {
  .divider {
    position: relative;
    width: 100%;
    display: inline;
  }
}
</style>